.navbar .nav-link {
  color: var(--grey-color);
  position: relative;
  display: inline-block;
}

.navbar .nav-link:hover {
  color: var(--theme-color);
  cursor: pointer;
}

.navbar .nav-link:hover::after {
  color: var(--theme-color);
  cursor: pointer;
  width: 100%;
}
.active-class.nav-link {
  color: var(--theme-color);
  cursor: pointer;
}

.navbar .navbar-nav .nav-item {
  padding-left: 2rem;
  padding-right: 2rem;
}

.navbar .header-dropdown-container .navbar .header-dropdown {
  width: 220px;
  background-color: white;
  box-shadow: 0px 3px 16px #00000029;
  overflow: hidden;
  z-index: 1000;
}

.navbar .header-cart {
  height: 1.25rem;
}

.navbar .auth-btn {
  border: 1px solid #2a9bdf;
  background-color: #2a9bdf;
  font-weight: 500;
  color: white;
}

.sign-in-btn {
  background-color: white !important;
  color: rgb(51, 51, 51) !important;
  font-weight: 500 !important;
}

.auth-btn:hover {
  background-color: white;
  font-weight: 500;
  color: black;
}

.sign-in-btn:hover {
  background-color: #2a9bdf !important;
  color: white !important;
  font-weight: 500 !important;
}

.navbar .item-counter {
  display: inline-block;
  height: 0.75rem;
  min-width: 0.75rem;
  border-radius: 50%;
  background-color: var(--danger-color);
  position: absolute;
  color: white;
  top: -2px;
  right: -2px;
  font-size: 8px;
}
.navbar .wishlist-counter {
  display: inline-block;
  height: 0.75rem;
  min-width: 0.75rem;
  border-radius: 50%;
  background-color: var(--danger-color);
  position: absolute;
  color: white;
  top: -2px;
  right: -2px;
  font-size: 8px;
}

.navbar-brand img {
  width: 4rem;
}

.profile-image {
  width: 40px;
  height: 40px;
  overflow: hidden;
}

.dropdown {
  width: 150px;
  top: 110%;
  right: 0%;
}

.select-option:hover {
  background-color: #2a9bdf !important;
  color: white !important;
}

@media (max-width: 1100px) {
  .navbar .navbar-nav .nav-item {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media (max-width: 991px) {
  .navbar .navbar-nav .nav-item {
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .nav-link-res {
    border-bottom: 1px solid #00000029;
    padding-top: 10px;
    padding-bottom: 5px;
  }
  .nav-link-res:last-child {
    border-bottom: none;
    padding-top: 10px;
    padding-bottom: 5px;
  }
}

@media (max-width: 576px) {
  .profile-image {
    width: 20px;
    height: 20px;
    overflow: hidden;
  }
}
