.footer{
    background-color: var(--theme-color);
}
.footer .footer-logo{
    width:5rem;
}

.footer .footer-container{
    display: flex;
    column-gap:5rem;
    row-gap:1rem;
    flex-wrap: wrap;
    
}

.footer-social{
    display: flex;
    gap: 1rem;
}
.footer-social-icon{
    width: 1.5rem;
}
.footer-heading{
    font-size: 1.2rem;
}
.footer-link{
    font-size: 0.875rem;
}
.footer-link img{
    width: 1.5rem;
}

.footer-btn{
    position: absolute;
    background-color:var(--theme-color);
    top: -1.6rem;
    right: 1rem;
    height:2.5rem;
    width: 2.5rem;
    display: flex;
    justify-content: center;
    cursor: pointer;
}
.footer-btn img{
    width: 90%;
}
.footer-border .left-border{
    height: 1px;
    background: linear-gradient(to left, white, rgba(255,255,255,0.9), rgba(255,255,255,0.2),rgba(255,255,255,0));
}
.footer-border .right-border{
    background: linear-gradient(to right, white, rgba(255,255,255,0.9), rgba(255,255,255,0.2),rgba(255,255,255,0));
    height: 1px;
}
@media (min-width:1200px){
    .footer .footer-container{
        display: flex;
        column-gap:8rem;
        row-gap: 3rem;

    }
}

@media (max-width:576px){
    .footer-content-container{
        width: 50%;
    }
    .footer .footer-container{
        display: flex;
        column-gap:0rem;
        flex-wrap: wrap;        
    }
}