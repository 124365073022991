.product-detail-name {
  width: 47%;
}

.product-image {
  width: 80px;
  height: 80px;
}

.summary {
  background-color: #f5f5f6;
}

.checkout-btn {
  background-color: #0068a7;
}

.checkout-btn:hover {
  background-color: #033453;
}

.checkout-btn:focus {
  background-color: #033453;
}

.remove-btn {
  color: #f80b0b;
}

.sign-bg {
  background: #f0eeee;
}

.message-page {
  height: 30vh;
}

@media (min-width: 768px) and (max-width: 991px) {
  .shop-cart-p {
    padding-right: 0 !important;
  }
}
