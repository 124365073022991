.dashboard {
  height: 100vh;
}

.model-description-dash {
  min-width: 150px;
}

.dashboard-data {
  background: #eaeef6 !important;
}

.dashboard-profile-image {
  width: 55px;
  height: 55px;
}

.notifcation-circle {
  width: 55px;
  height: 55px;
}

.dashboard-profile-image-side {
  width: 50px;
  height: 50px;
}

.notification-counter {
  height: 17px;
  width: 17px;
  font-size: 0.7rem;
  background: #d30f0f;
}

.bgTheme {
  background: #0068a7;
  border-radius: 20px;
}

.side-baar {
  background-color: #0e0e0e86;
  z-index: 9999;
}

.blocks {
  background: #f5f5f5;
}

.image-block {
  width: 40px;
  height: 40px;
}

.image-container-dash {
  height: 150px;
  width: 150px;
  box-shadow: none;
  border: 8px solid #d9d9d9;
}

.camera-icon {
  bottom: 0;
  right: 10%;
  width: 40px;
  height: 40px;
  background-color: #d9d9d9;
}

.input-color {
  background: #f5f5f5;
}

.input-color:focus {
  box-shadow: none;
  background: #f5f5f5;
}

.eye-icon {
  position: absolute;
  top: 50%;
  right: 0.7rem;
  transform: translate(0%, -50%);
  font-size: 1.2rem;
}

.save-btn {
  background: #0068a7;
}

.save-btn:hover {
  background: #015081;
}

.save-btn:focus {
  background: #015081;
}

.save-btn:active {
  background: #015081 !important;
}

.save-btn:focus-within {
  background: #015081;
}

.save-btn:focus-visible {
  background: #015081;
}

.save-btn:disabled {
  background: #015081;
}

.notification-border-bottom {
  border-bottom: 1px solid #000000;
}
.notification-border-bottom:last-child {
  border-bottom: none;
  padding-bottom: 0 !important;
}

.product-img {
  width: 60px;
  height: 60px;
}

.product-img {
  width: 40px;
  height: 40px;
}

tr th {
  padding: 0;
}

tr td {
  padding: 0;
}

.table-dash tr.table-header-dash th div {
  background-color: white;
  color: black;
}

tr th div {
  border-top: 1px solid #e3e3e3;
  border-bottom: 1px solid #e3e3e3;
  font-weight: 500;
}

tr th:first-child div {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-left: 1px solid #e3e3e3;
}

tr th:last-child div {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-right: 1px solid #e3e3e3;
}

tr td div {
  border-top: 1px solid var(--lighter-grey);
  border-bottom: 1px solid var(--lighter-grey);
  margin-top: 0.4rem;
}

tr td:first-child div {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-left: 1px solid var(--lighter-grey);
}

tr td:last-child div {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-right: 1px solid var(--lighter-grey);
}

.table-row-color {
  background: #f5f5f5;
}

.form-modal {
  padding-left: 0 !important;
}

.modal-dialog {
  max-width: 75%;
  margin-right: auto;
  margin-left: auto;
}

.make-text-wrap {
  text-wrap: nowrap;
}

.model-product-name {
  color: var(--theme-color);
  text-decoration: none;
}
.model-product-name:hover {
  text-decoration: underline;
}

.order-id {
  color: var(--theme-color);
  text-decoration: none;
}
.order-id:hover {
  text-decoration: underline;
}

.img-size {
  width: 27px;
}

.details .input-field {
  width: 100%;
}
.details .modal-content {
  width: auto;
}
.details .modal-dialog-centered {
  display: flex;
  justify-content: center;
  align-items: center;
}
.details .two-inputs {
  width: 100%;
}

@media screen and (max-width: 1199px) {
  .web-logo {
    width: 50px !important;
    height: 60px !important;
  }
  .dashboard-profile-image {
    width: 40px;
    height: 40px;
  }
  .dashboard-profile-image-side {
    width: 40px;
    height: 40px;
  }
  .notifcation-circle {
    width: 50px;
    height: 50px;
  }
  .mail {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 768px) {
  .modal-dialog {
    max-width: 75%;
    margin-right: auto;
    margin-left: auto;
  }
  .content-boxes {
    height: 90%;
  }
  .image-container-dash {
    height: 100px;
    width: 100px;
  }
}

@media screen and (max-width: 568px) {
  .modal-dialog {
    margin-top: 20px;
    max-width: 80%;
    margin-right: auto;
    margin-left: auto;
  }
  .tabs-size {
    font-size: 0.7rem;
  }
  .img-size {
    width: 15px;
    height: 15px;
  }
  .make-text-wrap {
    text-wrap: wrap !important;
  }
}
