.show-case-container .show-case .child {
  overflow: auto;
  white-space: "nowrap";
}

.show-case-container .show-case img {
  object-fit: cover;
  width: 5.5rem;
  height: 5.5rem;
}

.home .side-baar {
  border: 1px solid var(--theme-light-color);
}

.home .side-baar .side-baar-header {
  background-color: var(--theme-light-color);
  color: white;
}

.home .filter-btn {
  padding: 0;
  border: 1px solid var(--grey-color);
  background-color: inherit;
}

.home .filter-btn-clear {
  background-color: var(--grey-color);
  color: white;
  border: none;
}

.home .sort-icon {
  height: 1.1rem;
}

.home .home-product {
  border: 1px solid var(--light-grey);
  border-radius: 8px;
}

.home .home-product .product-name {
  color: var(--theme-color);
  border-bottom: 1px solid var(--theme-color);
}

.home-product {
  gap: 5rem;
}

.home-product .product-info {
  width: calc(100% - 13rem);
}

.product-showCase .product-image {
  width: 8.5rem;
  height: 8.5rem;
  object-fit: cover;
}

.product-social .img1 {
  height: 1rem;
}

.product-social .img2 {
  height: 1.2rem;
}

.slider {
  overflow: auto;
  gap: 1rem;
}

.slider::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.angle-left {
  position: absolute;
  top: 50%;
  left: -20px;
  cursor: pointer;
  transform: translate(0%, -50%);
  font-size: 2rem;
}

.angle-right {
  position: absolute;
  top: 50%;
  right: -20px;
  transform: translate(0%, -50%);
  z-index: 200;
  cursor: pointer;
  font-size: 2rem;
}

.slider-card {
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.slider-card img {
  border-radius: 15px;
  border-bottom-right-radius: 0px;
}

.slider-star-container {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--theme-light-color);
  height: 2rem;
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.slider-star-container img {
  height: 1rem;
}

.search-keywords input {
  width: 100%;
  height: 2rem;
  font-size: 0.8rem;
  padding: 0rem 2.5rem 0 0.3rem;
  border: 1px solid var(--light-grey);
}

.search-keywords input:focus {
  border: 1px solid var(--theme-light-color);
  outline: none;
}

.sarchkeywords-btn {
  background-color: var(--theme-light-color);
  color: white;
  border: none;
  outline: none;
  position: absolute;
  right: 0;
  height: 100%;
  width: 2.2rem;
}

.sidbaar-accordion-body {
  transition: 0.2s ease-in;
  overflow: hidden;
}

.rotated-icon {
  rotate: 90deg;
}

.side-baar-select select,
.price-range-input {
  width: 100%;
  height: 2rem;
  border: 1px solid var(--light-grey);
  outline: none;
  font-size: 0.8rem;
}

.side-baar-select select:focus,
.price-range-input:focus {
  border: 1px solid var(--theme-light-color);
}

.featureProduct-description {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  /* Sirf 3 lines tak text show karega */
  overflow: hidden;
  text-overflow: ellipsis;
}

.slider-card {
  width: 200px;
}

.slider-card .slider-img {
  height: 125px;
  object-fit: cover;
}

.pagination-btn {
  top: 20%;
  right: -2.3rem;
}

.arrow-btn {
  background: #2a9bdf !important;
  border: 0 !important;
}

.arrow-btn:focus {
  background: #0766a1 !important;
  border: 0 !important;
}

.arrow-btn:hover {
  background: #0766a1 !important;
  border: 0 !important;
}

.sort-btn-click {
  background: #2a9bdf !important;
  border: 0 !important;
  color: white;
}

.btn:disabled {
  border: 0;
}

.small-text {
  font-size: 0.7rem;
}

.suggestion-keywords {
  top: 40px !important;
}
.keyword:hover {
  background-color: #2a9bdf !important;
  color: white !important;
}
.keyword:focus {
  background-color: #2a9bdf !important;
  color: white !important;
}

.keyword-ul {
  max-height: 300px;
  background-color: white;
  overflow-y: auto;
}

.sort-menu li:hover {
  background-color: #2a9bdf !important;
  color: white !important;
}
.sort-menu li:focus {
  background-color: #2a9bdf !important;
  color: white !important;
}

.sort-menu {
  top: 30px;
}

.filter-section {
  border-bottom: 1px solid var(--light-grey);
  padding-bottom: 12px;
}

.showcase-styling {
  position: absolute;
  top: 10%;
  right: 110%;
  opacity: 0;
  visibility: hidden;
  transform: translateY(20px);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out,
    visibility 0.5s linear 0.5s;
}

.showcase-styling.active {
  right: 110%;
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.blue-badge {
  background: #0d6efd;
}

.purple-badge {
  background: #a020f0;
}

.discount-badge {
  font-size: 0.7rem;
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

/* Apply the animation to the discount badge */
.discount-badge {
  animation: blink 2s infinite;
}

.discount-badg-product {
  transform: rotateZ(43deg);
  width: 200px;
  right: -61px;
  top: 21px;
  z-index: 50;
}

@media (max-width: 1399px) {
  .home-product {
    gap: 5rem;
  }

  .home-product .product-info {
    width: calc(100% - 11rem);
  }
}

@media (max-width: 1199px) {
  .home-product {
    gap: 4rem;
  }

  .home-product .product-info {
    width: calc(100% - 10rem);
  }

  .arrow-size {
    font-size: 12px;
  }

  .pagination-btn {
    top: 5.2rem;
    right: -1.2rem;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .side-baar-parent {
    padding-right: 0 !important;
  }
  .angle-left {
    position: absolute;
    top: 50%;
    left: -15px;
    transform: translate(0%, -50%);
    font-size: 1.5rem;
  }

  .angle-right {
    position: absolute;
    top: 50%;
    right: -15px;
    transform: translate(0%, -50%);
    z-index: 10000;
    cursor: pointer;
    font-size: 1.5rem;
  }
}

@media (max-width: 767px) {
  .home-product {
    gap: 3rem;
  }

  .product-showCase .product-image {
    width: 5rem;
    height: 5rem;
    object-fit: cover;
  }

  .home-product .product-info {
    width: calc(100% - 8rem);
  }

  .angle-left {
    position: absolute;
    top: 50%;
    left: -10px;
    transform: translate(0%, -50%);
    font-size: 1.2rem;
  }

  .angle-right {
    position: absolute;
    top: 50%;
    right: -10px;
    transform: translate(0%, -50%);
    z-index: 10000;
    cursor: pointer;
    font-size: 1.2rem;
  }
}

@media (max-width: 576px) {
  .slider-star-container {
    height: 2.5rem;
    width: 2.5em;
  }

  .slider-star-container img {
    height: 1.2rem;
  }
}

#products-container #loader {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgb(255, 255, 255);
}

#products-container #loader.blur {
  background-color: rgb(255, 255, 255, 0.8);
}

@media (min-width: 768px) {
  .side-baar-parent {
    position: sticky;
    top: 0;
  }
}
